/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';

import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';
import { useLanguage } from '~/hooks/Language';
import { useChat } from '~/hooks/Chat';

import { Container, Welcome, Values, Table, Skeleton } from './styles';
import userIcon from '~/assets/icons/student-icon.svg';
import arrowDown from '~/assets/icons/arrow-blue-down.svg';
import arrowUp from '~/assets/icons/arrow-blue-up.svg';
import affiliate from '~/assets/icons/affiliate-icon.svg';
import franchise from '~/assets/icons/franchise-purple-icon.svg';
import lead from '~/assets/animations/lead.json';

interface IChat {
  id: string;
}

interface ILabelsStatus {
  name: string;
  type: string;
  qualified: string;
  progress: string;
  origin: string;
  email: string;
  joined: string;
  action: string;
}

interface ICourse {
  id: string;
  modules: {
    id: string;
    lessons: {
      id: string;
    }[];
  }[];
}

interface IOrder {
  id: string;
  amount_paid: string;
  status: string;
  course?: {
    id: string;
    title: string;
  };
}

interface IAffiliateOffer {
  offer_id: string;
}

interface IReferrerResponse {
  id: string;
  user: {
    id: string;
    name: string;
    email: string;
    referral_code: string;
    origin: string;
    avatar: {
      id: string;
      avatar_url: string;
    };
    affiliate: {
      id: string;
      active_affiliate: boolean;
      franchise_owner: boolean;
      type: string;
      affiliatesOffers: IAffiliateOffer[];
    };
    orders: IOrder[];
    lessonsUsers: {
      lesson_id: string;
    }[];
    foundersLeadsLead?: [];
  };
  created_at: string;
}

interface IReferrer {
  id: string;
  avatar: string;
  name: string;
  isLead: boolean;
  email: string;
  type: string;
  typeIcon: string;
  progress: string;
  joined: string;
  referral_code: string;
  qualified: boolean;
  className: string;
  origin: string;
}

const Referrals: React.FC = () => {
  const { user } = useAuth();
  const { addChat } = useChat();
  const { language } = useLanguage();
  const [referrers, setReferrers] = useState<IReferrer[]>([]);
  const [normalReferrers, setNormalReferrers] = useState<IReferrer[]>([]);
  const [loading, setLoading] = useState(true);
  const [labelsStatus] = useState({
    name: 'normal',
    type: 'normal',
    qualified: 'normal',
    progress: 'normal',
    origin: 'normal',
    email: 'normal',
    joined: 'normal',
    action: 'normal',
  });

  useEffect(() => {
    let totalData = 0;
    api
      .get<IReferrerResponse[]>(`referrers/${user.affiliate.id}`)
      .then(async (response) => {
        const responseCourse = await api.get<ICourse>(
          'courses/users/masterclass-onboarding'
        );
        const course = responseCourse.data;
        const responseModules = await api.get(`modules/users/${course.id}`);
        const modules = responseModules.data;
        let i = 0;
        for (const module of modules) {
          const responseLessons = await api.get(`lessons/modules/${module.id}`);
          modules[i].lessons = responseLessons.data;
          i += 1;
        }
        course.modules = modules;

        const data = response.data.map((referrer) => {
          const nameParts = referrer.user.name?.split(' ');
          const joined = `${language.my_referrals.joined} ${format(
            parseISO(referrer.created_at),
            'MMM dd, yyyy'
          )}`;
          let typeIcon = userIcon;
          let progress = 'Watched Introduction';
          let className = 'color-member';
          let qualified = false;

          let maxModuleIndex = -1;
          let maxLessonIndex = -1;
          if (referrer.user.lessonsUsers.length > 0) {
            course.modules.forEach((module, moduleIndex) => {
              let lessonIndexData = -1;
              module.lessons.forEach((lesson, lessonIndex) => {
                const lessonId = lesson.id;

                const foundLesson = referrer.user.lessonsUsers.find(
                  (lessonUserData) => lessonUserData.lesson_id === lessonId
                );

                if (foundLesson && moduleIndex >= maxModuleIndex) {
                  maxModuleIndex = moduleIndex;
                  if (lessonIndex > lessonIndexData) {
                    lessonIndexData = lessonIndex;
                    maxLessonIndex = lessonIndex;
                  } else {
                    maxLessonIndex = lessonIndexData;
                  }
                }
              });
            });
          }

          if (maxModuleIndex >= 0 && maxLessonIndex >= 0) {
            progress = `Module ${maxModuleIndex + 1}, Video ${
              maxLessonIndex + 1
            }`;
          }

          totalData = referrer.user.orders.reduce(
            (acumulador: number, currentValue: IOrder) => {
              if (currentValue.status === 'succeeded') {
                return acumulador + parseFloat(currentValue.amount_paid);
              }
              return acumulador + 0;
            },
            totalData
          );

          if (
            referrer.user.affiliate &&
            referrer.user.affiliate.affiliatesOffers &&
            referrer.user.affiliate.affiliatesOffers.length > 0
          ) {
            qualified = !!referrer.user.affiliate.affiliatesOffers.find(
              (affiliateOffer) =>
                affiliateOffer.offer_id ===
                '2ffe225b-fbad-4521-a68e-c98e43e4f99b'
            );

            const superAffiliate = !!referrer.user.affiliate.affiliatesOffers.find(
              (affiliateOffer) =>
                affiliateOffer.offer_id ===
                'cee44e14-66f7-42b2-ba1e-adccf8a47b60'
            );

            if (superAffiliate) {
              typeIcon = franchise;
              progress = 'Completed Offer 2';
              className = 'color-super';
            } else if (qualified) {
              typeIcon = affiliate;
              progress = 'Completed Offer 1';
              className = 'color-affiliate';
            }
          }

          const originParts = referrer.user.origin.split(', ');

          return {
            id: referrer.id,
            isLead: !!referrer.user.foundersLeadsLead,
            avatar: referrer.user.avatar?.avatar_url || '',
            name:
              nameParts && nameParts.length > 0
                ? `${nameParts[0]} ${nameParts[nameParts.length - 1]}`
                : 'Not added',
            email: referrer.user.email,
            type: referrer.user.affiliate?.type || 'New Member',
            typeIcon,
            progress,
            joined,
            referral_code: referrer.user.referral_code,
            qualified,
            className,
            origin: originParts[originParts.length - 1],
          };
        });
        setReferrers(data);
        setNormalReferrers(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    language.my_referrals.affiliate,
    language.my_referrals.joined,
    language.my_referrals.owner,
    language.my_referrals.user,
    user.affiliate.id,
  ]);

  const handleClickChat = useCallback(
    async (referrer: IReferrer) => {
      const response = await api.get<IChat>(
        `chats/${user.referral_code}-${referrer.referral_code}`,
        {
          params: {
            type: 'userToUser',
          },
        }
      );

      addChat({
        id: response.data.id,
        name: referrer.name,
        minimize: false,
      });
    },
    [addChat, user.referral_code]
  );

  const sortReferrers = useCallback(
    (key: keyof IReferrer, status) => {
      const newReferrers = normalReferrers.slice();
      let newStatus = '';
      if (status === 'normal') {
        newStatus = 'asc';
        newReferrers.sort((a, b) => {
          if (a[key] < b[key]) {
            return -1;
          }
          if (a[key] > b[key]) {
            return 1;
          }
          return 0;
        });
      } else if (status === 'asc') {
        newStatus = 'desc';
        newReferrers.sort((a, b) => {
          if (a[key] > b[key]) {
            return -1;
          }
          if (a[key] < b[key]) {
            return 1;
          }
          return 0;
        });
      } else if (status === 'desc') {
        newStatus = 'normal';
      }
      Object.keys(labelsStatus).forEach((labelStatus) => {
        if (labelStatus === key) {
          labelsStatus[labelStatus as keyof ILabelsStatus] = newStatus;
        } else {
          labelsStatus[labelStatus as keyof ILabelsStatus] = 'normal';
        }
      });
      setReferrers(newReferrers);
    },
    [labelsStatus, normalReferrers]
  );

  return (
    <>
      <Container>
        <div className="container px-0">
          <div className="w-100">
            <Welcome>
              <div className="row">
                <div className="col-12 my-4">
                  <h1 className="h4 h2-sm text-center text-lg-left">
                    {language.my_referrals.h1}
                  </h1>
                </div>
              </div>
            </Welcome>
            <div className="row justify-content-center">
              <Values className="col-11 col-xxl-10 mb-n5 d-md-flex justify-content-center mt-3 py-4">
                <div className="col-lg-4 text-center border-md-right">
                  <p className="h4 mb-0 mb-sm-3">
                    <span className={loading === true ? 'skeleton px-3' : ''}>
                      {referrers.length.toString().padStart(2, '0')}
                    </span>
                  </p>
                  <p className="mb-0">Total Members</p>
                </div>

                <>
                  <hr className="w-25 mx-auto d-md-none" />
                  <div className="col-lg-4 text-center border-md-right">
                    <p className="h4 mb-0 mb-sm-3">
                      <span className={loading === true ? 'skeleton px-3' : ''}>
                        {referrers
                          .filter((referrerData) => !referrerData.qualified)
                          .length.toString()
                          .padStart(2, '0')}
                      </span>
                    </p>
                    <p className="mb-0">Waitlist</p>
                  </div>
                </>

                <hr className="w-25 mx-auto d-md-none" />
                <div className="col-lg-4 text-center">
                  <p className="h4 mb-0 mb-sm-3">
                    <span className={loading === true ? 'skeleton px-3' : ''}>
                      {referrers
                        .filter((referrerData) => referrerData.qualified)
                        .length.toString()
                        .padStart(2, '0')}
                    </span>
                  </p>
                  <p className="mb-0">Qualified</p>
                </div>
              </Values>
            </div>
            <div className="bg-gray pb-4">
              <div className="overflow-auto scroll">
                <Table className="pt-5">
                  {loading && (
                    <Skeleton>
                      <div className="w-100 d-flex align-items-center justify-content-around line mb-3">
                        <div className="px-0 text-center my-3 d-flex justify-content-center align-items-center">
                          <div className="skeleton img-height rounded-circle mr-4" />
                          <span className="skeleton min-w">#########</span>
                        </div>
                        <div className="px-0 text-center">
                          <span className="skeleton mt-2">##########</span>
                        </div>
                        <div className="px-0 text-center">
                          <span className="skeleton mt-2">########</span>
                        </div>
                        <div className="px-0 text-center">
                          <span className="skeleton mt-2">##########</span>
                        </div>

                        <div className="px-0 text-center">
                          <span className="skeleton mt-2">##########</span>
                        </div>
                      </div>
                    </Skeleton>
                  )}

                  {!loading && (
                    <div className="d-flex orders mb-4 mt-5 px-4">
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn-order d-flex align-items-center mx-auto"
                          onClick={() =>
                            sortReferrers('name', labelsStatus.name)
                          }
                        >
                          Member
                          <span>
                            <img
                              src={arrowUp}
                              alt="Arrows"
                              className={`${
                                labelsStatus.name !== 'desc'
                                  ? 'd-flex'
                                  : 'd-none'
                              } ${
                                labelsStatus.name === 'normal' && 'mb-1'
                              } ml-2`}
                            />

                            <img
                              src={arrowDown}
                              alt="Arrows"
                              className={`${
                                labelsStatus.name !== 'asc'
                                  ? 'd-flex'
                                  : 'd-none'
                              } ${
                                labelsStatus.name === 'normal' && 'mt-1'
                              } ml-2`}
                            />
                          </span>
                        </button>
                      </div>
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn-order d-flex align-items-center mx-auto"
                          onClick={() =>
                            sortReferrers('type', labelsStatus.type)
                          }
                        >
                          Member Type
                          <span>
                            <img
                              src={arrowUp}
                              alt="Arrows"
                              className={`${
                                labelsStatus.type !== 'desc'
                                  ? 'd-flex'
                                  : 'd-none'
                              } ${
                                labelsStatus.type === 'normal' && 'mb-1'
                              } ml-2`}
                            />

                            <img
                              src={arrowDown}
                              alt="Arrows"
                              className={`${
                                labelsStatus.type !== 'asc'
                                  ? 'd-flex'
                                  : 'd-none'
                              } ${
                                labelsStatus.type === 'normal' && 'mt-1'
                              } ml-2`}
                            />
                          </span>
                        </button>
                      </div>
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn-order d-flex align-items-center mx-auto"
                          onClick={() =>
                            sortReferrers('qualified', labelsStatus.qualified)
                          }
                        >
                          Status
                          <span>
                            <img
                              src={arrowUp}
                              alt="Arrows"
                              className={`${
                                labelsStatus.qualified !== 'desc'
                                  ? 'd-flex'
                                  : 'd-none'
                              } ${
                                labelsStatus.qualified === 'normal' && 'mb-1'
                              } ml-2`}
                            />

                            <img
                              src={arrowDown}
                              alt="Arrows"
                              className={`${
                                labelsStatus.qualified !== 'asc'
                                  ? 'd-flex'
                                  : 'd-none'
                              } ${
                                labelsStatus.qualified === 'normal' && 'mt-1'
                              } ml-2`}
                            />
                          </span>
                        </button>
                      </div>
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn-order d-flex align-items-center mx-auto"
                          onClick={() =>
                            sortReferrers('progress', labelsStatus.progress)
                          }
                        >
                          Progress
                          <span>
                            <img
                              src={arrowUp}
                              alt="Arrows"
                              className={`${
                                labelsStatus.progress !== 'desc'
                                  ? 'd-flex'
                                  : 'd-none'
                              } ${
                                labelsStatus.progress === 'normal' && 'mb-1'
                              } ml-2`}
                            />

                            <img
                              src={arrowDown}
                              alt="Arrows"
                              className={`${
                                labelsStatus.progress !== 'asc'
                                  ? 'd-flex'
                                  : 'd-none'
                              } ${
                                labelsStatus.progress === 'normal' && 'mt-1'
                              } ml-2`}
                            />
                          </span>
                        </button>
                      </div>
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn-order d-flex align-items-center mx-auto"
                          onClick={() =>
                            sortReferrers('origin', labelsStatus.origin)
                          }
                        >
                          Source
                          <span>
                            <img
                              src={arrowUp}
                              alt="Arrows"
                              className={`${
                                labelsStatus.origin !== 'desc'
                                  ? 'd-flex'
                                  : 'd-none'
                              } ${
                                labelsStatus.origin === 'normal' && 'mb-1'
                              } ml-2`}
                            />

                            <img
                              src={arrowDown}
                              alt="Arrows"
                              className={`${
                                labelsStatus.origin !== 'asc'
                                  ? 'd-flex'
                                  : 'd-none'
                              } ${
                                labelsStatus.origin === 'normal' && 'mt-1'
                              } ml-2`}
                            />
                          </span>
                        </button>
                      </div>
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn-order d-flex align-items-center mx-auto"
                          onClick={() =>
                            sortReferrers('email', labelsStatus.email)
                          }
                        >
                          Email
                          <span>
                            <img
                              src={arrowUp}
                              alt="Arrows"
                              className={`${
                                labelsStatus.email !== 'desc'
                                  ? 'd-flex'
                                  : 'd-none'
                              } ${
                                labelsStatus.email === 'normal' && 'mb-1'
                              } ml-2`}
                            />

                            <img
                              src={arrowDown}
                              alt="Arrows"
                              className={`${
                                labelsStatus.email !== 'asc'
                                  ? 'd-flex'
                                  : 'd-none'
                              } ${
                                labelsStatus.email === 'normal' && 'mt-1'
                              } ml-2`}
                            />
                          </span>
                        </button>
                      </div>
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn-order d-flex align-items-center mx-auto"
                          onClick={() =>
                            sortReferrers('joined', labelsStatus.joined)
                          }
                        >
                          Date Joined
                          <span>
                            <img
                              src={arrowUp}
                              alt="Arrows"
                              className={`${
                                labelsStatus.joined !== 'desc'
                                  ? 'd-flex'
                                  : 'd-none'
                              } ${
                                labelsStatus.joined === 'normal' && 'mb-1'
                              } ml-2`}
                            />

                            <img
                              src={arrowDown}
                              alt="Arrows"
                              className={`${
                                labelsStatus.joined !== 'asc'
                                  ? 'd-flex'
                                  : 'd-none'
                              } ${
                                labelsStatus.joined === 'normal' && 'mt-1'
                              } ml-2`}
                            />
                          </span>
                        </button>
                      </div>
                      <div className="text-right">
                        <button
                          type="button"
                          className="btn-order d-flex align-items-center mx-auto"
                        >
                          Action
                          <span>
                            <img
                              src={arrowUp}
                              alt="Arrows"
                              className="d-flex mb-1 ml-2"
                            />

                            <img
                              src={arrowDown}
                              alt="Arrows"
                              className="d-flex mt-1 ml-2"
                            />
                          </span>
                        </button>
                      </div>
                    </div>
                  )}

                  {referrers.map((referrer) => (
                    <div className="d-flex align-items-center hover-row  px-4 mb-3">
                      <Link
                        to={`${process.env.PUBLIC_URL}/my-referrals/team-view/${referrer.referral_code}`}
                        key={referrer.id}
                        className="d-flex align-items-center justify-content-between line"
                      >
                        <div className="px-0 text-center my-3 d-flex justify-content-center align-items-center">
                          <div className="position-relative">
                            <img
                              src={referrer.avatar}
                              className="avatar mr-4"
                              alt="avatar"
                            />
                            {referrer.isLead && (
                              <div className="position-absolute lottie">
                                <Lottie
                                  options={{
                                    animationData: lead,
                                    autoplay: true,
                                    loop: true,
                                    rendererSettings: {
                                      preserveAspectRatio: 'xMidYMid slice',
                                    },
                                  }}
                                  width={69}
                                  height={69}
                                />
                                <p>Founders Lead</p>
                              </div>
                            )}
                          </div>
                          <span className="min-w">
                            {referrer.name.includes('undefined') === true
                              ? referrer.name.replace('undefined', '')
                              : `${referrer.name}`}
                          </span>
                        </div>
                        <div className="px-0 text-center">
                          <span className="fw-600">
                            <img src={referrer.typeIcon} alt="Icon" />{' '}
                            {referrer.type}
                          </span>
                        </div>
                        <div className="px-0 text-center">
                          <span>
                            {referrer.qualified ? 'Qualified' : 'Waitlist'}
                          </span>
                        </div>
                        <div className="px-0 text-center">
                          <span className={referrer.className}>
                            {referrer.progress}
                          </span>
                        </div>
                        <div className="px-0 text-center">
                          <span>{referrer.origin}</span>
                        </div>
                        <div className="px-0 text-center">
                          <span>
                            {`${referrer.email.substring(
                              0,
                              1
                            )}****${referrer.email.substring(6)}`}
                          </span>
                        </div>
                        <div className="px-0 text-center">
                          <span>{referrer.joined}</span>
                        </div>
                      </Link>
                      <div className="div-message px-0 text-right">
                        <button
                          type="button"
                          className="message"
                          onClick={() => handleClickChat(referrer)}
                        >
                          Message
                        </button>
                      </div>
                    </div>
                  ))}
                </Table>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Referrals;
