import React from 'react';
import { useParams } from 'react-router-dom';
import Lottie from 'react-lottie';
import blocked from '~/assets/animations/blocked.json';
import play from '~/assets/icons/play-icon.svg';
import padlock from '~/assets/icons/padlock-icon.svg';

import { Container } from './styles';

interface ButtonLessonProps {
  lesson: {
    id: string;
    title: string;
    duration: string;
    status: string;
    slug: string;
    finished: boolean;
    block: boolean;
  };
  slugCourse: string;
  onClickFinished?(lesson_id: string): void;
}

const ButtonLesson: React.FC<ButtonLessonProps> = ({
  lesson,
  slugCourse,
  onClickFinished,
}) => {
  const params = useParams<{ slugLesson: string }>();

  return (
    <Container
      className="position-relative lesson-border w-100 my-1 d-flex aling-items-center"
      active={lesson.slug === params.slugLesson}
      disabled={lesson.block}
    >
      <div
        className={`${
          !lesson.block && 'd-none'
        } tip-block w-100 align-items-center`}
      >
        <div className="">
          <Lottie
            options={{
              animationData: blocked,
              autoplay: true,
              loop: true,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            width={54}
            height={54}
          />
        </div>
        <p className="mb-0 py-2 py-sm-3 pr-2">
          <span>Unlock the next video by</span> watching the previous one to the
          end.{' '}
          <span role="img" aria-label="box">
            👋
          </span>
        </p>
      </div>
      <a
        href={`${process.env.PUBLIC_URL}/lessons/${slugCourse}/${lesson.slug}`}
        className="d-flex align-items-center justify-content-between px-4 py-2 w-100"
      >
        <button
          type="button"
          onClick={() => onClickFinished && onClickFinished(lesson.id)}
          className="border-0 bg-transparent mr-3"
          disabled
        >
          {!lesson.block ? (
            <img src={play} alt="Icon Button" />
          ) : (
            <img src={padlock} alt="Icon Button" />
          )}
        </button>

        <div className="w-100 d-flex aling-items-center">
          <p className="mb-0 ml-1 line-height">{lesson.title}</p>
        </div>
      </a>
    </Container>
  );
};

export default ButtonLesson;
