import styled from 'styled-components';

export const Container = styled.div`
  .bg-gray {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 0 15px;
  }

  .scroll {
    ::-webkit-scrollbar {
      border-radius: 4px !important;
      height: 8px !important;
    }
  }

  .lottie {
    top: 20px;
    left: 20px;
    transition-duration: 0.3s;
    border-radius: 15px;
    border: 1px solid transparent;
    background: transparent;
    box-shadow: 4px 15px 25px 0px transparent;
    display: flex;
    width: max-content;
    padding-right: 26px;
    align-items: center;
    justify-content: center;
    z-index: 2;

    p {
      opacity: 0;
      visibility: hidden;
      transition-duration: 0.3s;
      color: #021027;
      font-weight: 500;
      margin-bottom: 0;
    }

    :hover {
      border: 1px solid #dedede;
      background: #fff;
      box-shadow: 4px 15px 25px 0px rgba(0, 0, 0, 0.08);

      p {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    zoom: 0.7;
    .container {
      max-width: 1620px !important;
    }
  }

  @media screen and (min-width: 1400px) {
    zoom: 0.8;
  }

  @media screen and (min-width: 1600px) {
    zoom: 0.9;
  }

  @media screen and (min-width: 1750px) {
    zoom: 1;
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #021027;
  }
  p {
    color: #6f6f6f;
  }
`;

export const Values = styled.div`
  background: #fafafa;
  border: 1px solid #e4e4e4;
  box-shadow: -2px 5px 20px rgba(0, 0, 0, 0.03);
  border-radius: 20px;

  p {
    color: #8c8c8c;
    font-weight: 400;
    span {
      color: #021027;
      font-weight: 600;
    }
  }

  hr {
    background: #d0d0d0;
  }

  .skeleton {
    background-color: #f1f1f1 !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #f1f1f1 0%,
        rgba(227, 227, 227, 0) 0%,
        rgba(227, 227, 227, 0) 10%,
        rgba(227, 227, 227, 0.2) 20%,
        rgba(227, 227, 227, 0.5) 40%,
        rgba(227, 227, 227, 0.8) 50%,
        rgba(227, 227, 227, 0.2) 80%,
        rgba(227, 227, 227, 0) 90%,
        rgba(227, 227, 227, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  .dark-gray {
    color: #606060;
  }

  @media screen and (min-width: 768px) {
    .border-md-right {
      border-right: solid 1px #d0d0d0;
    }
  }
`;

export const Table = styled.div`
  min-width: 1280px;
  margin-bottom: 10px;

  .min-w {
    min-width: 170px;
  }

  a {
    text-decoration: none;
  }

  .orders {
    width: max-content;
    > div:nth-child(1) {
      width: 254px !important;
    }

    > div:nth-child(2) {
      width: 188px !important;
    }

    > div:nth-child(3) {
      width: 90px !important;
    }

    > div:nth-child(4) {
      width: 220px !important;
    }

    > div:nth-child(5) {
      width: 200px !important;
    }

    > div:nth-child(6) {
      width: 295px !important;
    }

    > div:nth-child(7) {
      width: 190px !important;
    }

    > div:nth-child(8) {
      width: 100px !important;
    }
  }

  .btn-order {
    color: #021027;
    font-size: 14px;
    font-weight: 700;
    border: none;
    background: transparent;
  }

  .message {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    border-radius: 10px;
    background: #2f2f2f;
    border: none;
    width: 66px;
    height: 30px;
    transition-duration: 0.3s;
    :hover {
      opacity: 0.9;
    }
  }

  .hover-row {
    width: max-content;

    :hover {
      background-color: #f7f7f7;
      border-radius: 15px;
    }
  }

  .fw-600 {
    font-weight: 600;
  }

  /* .line {
    > div {
      width: 308px !important;
      overflow-wrap: break-word;
    }

    > div:nth-child(2),
    > div:nth-child(3) {
      width: 192px !important;
    }

    > div:nth-child(4) {
      width: 300px !important;
    }
  } */

  .line {
    > div:nth-child(1) {
      width: 254px !important;
      overflow-wrap: break-word;
    }

    > div:nth-child(2) {
      width: 188px !important;
    }

    > div:nth-child(3) {
      width: 90px !important;
    }

    > div:nth-child(4) {
      width: 220px !important;
    }

    > div:nth-child(5) {
      width: 200px !important;
    }

    > div:nth-child(6) {
      width: 295px !important;
    }

    > div:nth-child(7) {
      width: 190px !important;
    }
  }

  .div-message {
    width: 100px !important;
  }

  img.avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #303030;
  }

  div {
    span {
      color: #6f6e6e;
      font-size: 18px;
    }
  }

  .color-member,
  .color-affiliate,
  .color-super {
    font-weight: 500;
  }

  .color-member {
    color: #0d7cff;
  }

  .color-affiliate {
    color: #05b850;
  }

  .color-super {
    color: #e82e99;
  }

  @media screen and (max-width: 575px) {
    div {
      span {
        color: #6f6e6e;
      }
    }
  }
`;

export const Skeleton = styled.div`
  border-radius: 20px;
  background-color: #ffffff;
  z-index: 0;

  .img-height {
    height: 60px;
    width: 60px;
  }

  .skeleton {
    background-color: #f1f1f1 !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #f1f1f1 0%,
        rgba(227, 227, 227, 0) 0%,
        rgba(227, 227, 227, 0) 10%,
        rgba(227, 227, 227, 0.2) 20%,
        rgba(227, 227, 227, 0.5) 40%,
        rgba(227, 227, 227, 0.8) 50%,
        rgba(227, 227, 227, 0.2) 80%,
        rgba(227, 227, 227, 0) 90%,
        rgba(227, 227, 227, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;
